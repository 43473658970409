body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.order-status {
  display: inline-flex;
  line-height: 2.5em;
  color: #777;
  background: #e5e5e5;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
}
.order-status > span {
  margin: 0 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-status.status-completed {
  background: #c8d7e1;
  color: #2e4453;
}

.order-status.status-on-hold {
  background: #f8dda7;
  color: #94660c;
}

.order-status.status-failed {
  background: #eba3a3;
  color: #761919;
}

.order-status.status-processing {
  background: #c6e1c6;
  color: #5b841b;
}

.order-status.status-trash {
  background: #eba3a3;
  color: #761919;
}
